import { ANIMATION_CONFIG } from "../config/constants";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import barba from "@barba/core";

export const PageTransition = {
  menuInstance: null,
  curtain: null,

  setMenuInstance(instance) {
    this.menuInstance = instance;
  },

  createCurtain() {
    const curtain = document.createElement("div");
    curtain.className = "page-transition-curtain";
    document.body.appendChild(curtain);

    const style = document.createElement("style");
    style.textContent = `
      .page-transition-curtain {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #0d1717;
        z-index: 9999;
        will-change: transform;
        transform: translateY(100%);
      }
    `;
    document.head.appendChild(style);

    return curtain;
  },

  async init() {
    gsap.registerPlugin(ScrollTrigger);
    this.curtain = this.createCurtain();
    const self = this;

    barba.init({
      transitions: [
        {
          name: "curtain-transition",
          async leave(data) {
            const header = document.querySelector(".header");

            if (self.menuInstance && header.classList.contains("active-menu")) {
              await new Promise((resolve) => {
                const tl = gsap.timeline({
                  onComplete: resolve,
                });

                const menuTl = self.menuInstance.closeMenu();
                tl.add(menuTl).to({}, { duration: 0.1 });
              });
            }

            // First animation: the curtain rises from bottom to top
            await gsap
              .timeline()
              .set(self.curtain, {
                y: "100%",
              })
              .to(self.curtain, {
                y: "0%",
                duration: ANIMATION_CONFIG.TRANSITION.DURATION,
                ease: "power2.inOut",
              })
              .to(
                data.current.container,
                {
                  opacity: 0,
                  duration: ANIMATION_CONFIG.TRANSITION.DURATION / 2,
                },
                ANIMATION_CONFIG.TRANSITION.DURATION / 2,
              );
          },
          async enter(data) {
            gsap.set(data.next.container, {
              opacity: 0,
            });

            // Second animation: show a new page and move the curtain up.
            await gsap
              .timeline()
              .to(data.next.container, {
                opacity: 1,
                duration: ANIMATION_CONFIG.TRANSITION.DURATION / 2,
              })
              .to(self.curtain, {
                y: "-100%",
                duration: ANIMATION_CONFIG.TRANSITION.DURATION,
                ease: "power2.inOut",
              })
              .set(self.curtain, {
                y: "100%", // Return the curtain to its initial position
              });
          },
        },
      ],
      views: [
        {
          namespace: "all",
          afterEnter() {
            this.initPageScripts();
          },
        },
      ],
    });

    this.initHooks();
  },

  initPageScripts() {
    console.log("Initializing page-specific scripts");
    Splitting();
    ScrollOut({ threshold: 0.5, once: false });
  },

  initHooks() {
    barba.hooks.before(() => {
      console.log("Transition starting...");
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    });
    barba.hooks.enter(() => console.log("Entering new page..."));
    barba.hooks.leave(() => console.log("Leaving current page..."));
    barba.hooks.after(() => {
      window.scrollTo(0, 0);
    });
  },
};
