import "focus-visible";
import { inject } from "@vercel/analytics";
import { DateFormatter } from "./utils/dateFormatter";
import { CoordinatesGenerator } from "./utils/coordinatesGenerator";
import { SmoothScroll } from "./modules/scroll";
import { Menu } from "./modules/menu";
import { PageTransition } from "./modules/pageTransition";
import gsap from "gsap";

// Constants for the cursor
const CURSOR_SELECTORS = {
  main: ".cursor",
  hover: '[data-cursor="hover"]',
};

const CURSOR_DEFAULTS = {
  normal: { scale: 1 },
  hover: { scale: 2.2 },
};

class CursorManager {
  constructor() {
    this.cursor = document.querySelector(CURSOR_SELECTORS.main);
    this.hoverElements = document.querySelectorAll(CURSOR_SELECTORS.hover);
    this.initialize();
  }

  initialize() {
    gsap.set(CURSOR_SELECTORS.main, { force3D: true });
    this.bindEvents();
  }

  bindEvents() {
    document.addEventListener("mousemove", this.handleMouseMove.bind(this));
    document.body.addEventListener("mouseleave", this.handleMouseLeave.bind(this));
    document.body.addEventListener("mouseenter", this.handleMouseEnter.bind(this));
    this.setupHoverEffects();
  }

  handleMouseMove(e) {
    gsap.to(CURSOR_SELECTORS.main, {
      x: e.clientX - 16,
      y: e.clientY - 16,
      ease: "power3",
    });
  }

  handleMouseLeave() {
    gsap.to(CURSOR_SELECTORS.main, {
      scale: 0,
      duration: 0.1,
      ease: "none",
    });
  }

  handleMouseEnter() {
    gsap.to(CURSOR_SELECTORS.main, {
      ...CURSOR_DEFAULTS.normal,
      duration: 0.1,
      ease: "none",
    });
  }

  setupHoverEffects() {
    this.hoverElements.forEach((element) => {
      element.addEventListener("mouseenter", () => {
        gsap.to(CURSOR_SELECTORS.main, CURSOR_DEFAULTS.hover);
      });

      element.addEventListener("mouseleave", () => {
        gsap.to(CURSOR_SELECTORS.main, CURSOR_DEFAULTS.normal);
      });
    });
  }
}

class UIManager {
  static updateElements() {
    this.updateDateDisplay();
    this.updateCoordinatesDisplay();
    this.updateYearDisplay();
  }

  static updateDateDisplay() {
    const element = document.getElementById("date-display");
    if (element) {
      element.textContent = DateFormatter.formatDate();
    }
  }

  static updateCoordinatesDisplay() {
    const element = document.getElementById("coordinates-display");
    if (element) {
      const coords = CoordinatesGenerator.getRandomCoordinates();
      element.innerHTML = CoordinatesGenerator.formatCoordinates(coords.latitude, coords.longitude);
    }
  }

  static updateYearDisplay() {
    const element = document.getElementById("currentYear");
    if (element) {
      element.textContent = DateFormatter.getCurrentYear();
    }
  }
}

class App {
  constructor() {
    this.cursor = null;
    this.menu = null;
    this.initialize();
  }

  initialize() {
    document.addEventListener("DOMContentLoaded", () => {
      this.initializeModules();
      this.initializeUI();
    });
  }

  initializeModules() {
    inject();

    new SmoothScroll();
    this.menu = new Menu();
    PageTransition.setMenuInstance(this.menu);
    PageTransition.init();

    this.cursor = new CursorManager();
  }

  initializeUI() {
    UIManager.updateElements();
  }
}

// Application initialization
new App();
