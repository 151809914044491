export const CoordinatesGenerator = {
  getRandomCoordinate(min, max) {
    return (Math.random() * (max - min) + min).toFixed(6);
  },

  toDegreesMinutes(coord) {
    const degrees = Math.floor(Math.abs(coord));
    const minutes = Math.floor((Math.abs(coord) - degrees) * 60);
    return `${degrees}°${minutes}'`;
  },

  getRandomCoordinates() {
    return {
      latitude: this.getRandomCoordinate(-90, 90),
      longitude: this.getRandomCoordinate(-180, 180),
    };
  },

  formatCoordinates(latitude, longitude) {
    const latDirection = latitude >= 0 ? "N" : "S";
    const longDirection = longitude >= 0 ? "E" : "W";
    const formattedLat = `${this.toDegreesMinutes(latitude)}${latDirection}`;
    const formattedLong = `${this.toDegreesMinutes(longitude)}${longDirection}`;
    return `${formattedLat}<br>${formattedLong}`;
  },
};
