export const DateFormatter = {
  getDayName(date) {
    const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    return days[date.getDay()];
  },

  formatDate() {
    const date = new Date();
    const dayName = this.getDayName(date);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    return `${dayName} ${day}.${month}`;
  },

  getCurrentYear() {
    return new Date().getFullYear();
  },
};
