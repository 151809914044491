import { ANIMATION_CONFIG } from "../config/constants";

import Lenis from "lenis";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export class SmoothScroll {
  constructor() {
    this.lenis = new Lenis({
      duration: ANIMATION_CONFIG.SCROLL.DURATION,
      easing: (t) => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)),
      direction: "vertical",
      gestureDirection: "vertical",
      smoothWheel: true,
      smoothTouch: false,
      wheelMultiplier: ANIMATION_CONFIG.SCROLL.WHEEL_MULTIPLIER,
    });

    this.init();
  }

  init() {
    ScrollTrigger.addEventListener("refresh", () => this.lenis.resize());
    this.lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      this.lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }
}
