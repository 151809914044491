import { ANIMATION_CONFIG } from "../config/constants";
import gsap from "gsap";

export class Menu {
  constructor() {
    this.menu = document.querySelector(".menu");
    this.overlayBg = document.querySelector(".overlay-menu");
    this.header = document.querySelector(".header");
    this.bentoMenu = document.getElementById("bentoMenu");

    this.init();
  }

  init() {
    this.menu.addEventListener("click", () => this.toggleMenu());
    this.overlayBg.addEventListener("click", (e) => this.handleOverlayClick(e));
    this.bentoMenu.addEventListener("click", () => this.toggleBentoMenu());
  }

  handleOverlayClick(e) {
    if (e.target === this.overlayBg) {
      this.closeMenu();
    }
  }

  toggleMenu() {
    this.header.classList.contains("active-menu") ? this.closeMenu() : this.openMenu();
  }

  openMenu() {
    if (this.header.classList.contains("active-menu")) return;

    this.header.classList.add("active-menu");
    const tl = gsap.timeline();

    gsap.set(".main-menu--items li", { x: -167, autoAlpha: 0 });
    gsap.set(".main-social--items li", { x: -167, autoAlpha: 0 });

    tl.to(".overlay-menu", {
      duration: 0.3,
      opacity: 1,
      visibility: "visible",
      zIndex: 15,
    })
      .to(
        ".main-menu",
        {
          duration: 0.7,
          y: 0,
          ease: ANIMATION_CONFIG.MENU.EASE,
        },
        0,
      )
      .to(
        ".main-menu--items li",
        {
          x: 0,
          stagger: { amount: 0.5 },
          ease: "power2.out",
          autoAlpha: 1,
        },
        0.5,
      )
      .to(
        ".main-social--items li",
        {
          x: 0,
          stagger: { amount: 0.5 },
          ease: "power2.out",
          autoAlpha: 1,
        },
        0.5,
      )
      .to(".main-menu--socials div", {
        opacity: 1,
        stagger: ANIMATION_CONFIG.MENU.STAGGER,
        ease: "power3.inOut",
      });
  }

  closeMenu() {
    if (!this.header.classList.contains("active-menu")) return;

    this.header.classList.remove("active-menu");
    const tl = gsap.timeline();

    tl.to(".main-menu", {
      duration: 0.7,
      y: -582,
      ease: ANIMATION_CONFIG.MENU.EASE,
    }).to(
      ".overlay-menu",
      {
        autoAlpha: 0,
        zIndex: -10,
      },
      0.4,
    );

    this.resetBurger();
    return tl;
  }

  resetBurger() {
    if (this.bentoMenu.classList.contains("active")) {
      this.bentoMenu.classList.remove("active");
      return this.closeBentoAnimation();
    }
    return gsap.timeline();
  }

  toggleBentoMenu() {
    const isActive = this.bentoMenu.classList.contains("active");
    const animation = isActive ? this.closeBentoAnimation() : this.openBentoAnimation();
    animation.play();
    this.bentoMenu.classList.toggle("active");
  }

  openBentoAnimation() {
    const tl = gsap.timeline();
    const centerConfig = {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      duration: ANIMATION_CONFIG.MENU.DURATION,
    };

    tl.to(this.bentoMenu, {
      rotation: 45,
      duration: ANIMATION_CONFIG.MENU.DURATION,
      onStart: () => {
        this.bentoMenu.classList.add("active");
      },
    })
      .to("span:nth-child(1)", centerConfig, 0)
      .to("span:nth-child(3)", centerConfig, 0)
      .to("span:nth-child(7)", centerConfig, 0)
      .to("span:nth-child(9)", centerConfig, 0);

    return tl;
  }

  closeBentoAnimation() {
    const tl = gsap.timeline();
    const positions = {
      1: { top: "0", left: "0", transform: "translate(0, 0)" },
      3: { top: "0", left: "100%", transform: "translate(-100%, 0)" },
      7: { top: "100%", left: "0", transform: "translate(0, -100%)" },
      9: { top: "100%", left: "100%", transform: "translate(-100%, -100%)" },
    };

    tl.to(this.bentoMenu, {
      rotation: 0,
      duration: ANIMATION_CONFIG.MENU.DURATION,
      onStart: () => {
        // Удаляем класс active в начале анимации
        this.bentoMenu.classList.remove("active");
      },
    });

    Object.entries(positions).forEach(([child, position]) => {
      tl.to(
        `span:nth-child(${child})`,
        {
          ...position,
          duration: ANIMATION_CONFIG.MENU.DURATION,
          onComplete: () => {
            this.bentoMenu.querySelector(`span:nth-child(${child})`).style.transform =
              position.transform;
          },
        },
        0,
      );
    });

    return tl;
  }
}
